<template>
  <section>
    <DeleteModal
      v-model="showDeleteModal"
      error-message="parameterKeyIsNotCorrect"
      :item="selectedParameter"
      label="parameterKey"
      name="key"
      @on-confirm="onConfirmDelete()"
    />
    <content-header :title="$t('parametermaintenance')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filter')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getParameters(1)"
        />
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <separator class="my-4" :text="$t('listOfParameters')" />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="goToCreateParameter"
            :text="$t('create')"
          />
        </div>
      </div>
      <div class="row">
        <div
          v-for="(parameter, index) in parameters.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="parameter"
            @edit="editParameter(parameter)"
            @on-delete="onDeleteParameter(parameter)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="parameters.count > 0"
        id="pgPager"
        class="my-4"
        :data="parameters"
        :page-size="pageSize"
        @pagination-go-page="getParameters($event)"
        @pagination-rows-per-page="getParameters(pageIndex, $event)"
      />
    </div>

    <custom-modal id="create-modal" ref="create-modal-ref" size="lg">
      <ParameterCreate
        :parameter-model="parameterModel"
        @on-cancel="onCancelCreate"
        @on-submit="onSubmitCreate"
      />
    </custom-modal>
  </section>
</template>
<script>
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader.vue';
import FilterManager from '@/components/FilterManager';
import DeleteModal from '@/components/DeleteModal';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import Separator from '@/components/Separator';
import SearchResult from './components/SearchResult.vue';
import CustomPagination from '@/components/CustomPagination.vue';
import {
  getAllParameters as _getAllParameters,
  getParameters as _getParameters,
  deleteParameter as _deleteParameter,
} from '@/services/SetupParameterService';
import CustomModal from '../../components/basics/modal/CustomModal.vue';
import ParameterCreate from './components/Create.vue';

export default {
  name: 'ParametersList',
  components: {
    contentHeader,
    SubHeader,
    FilterManager,
    CustomNotFound,
    Separator,
    SearchResult,
    CustomPagination,
    DeleteModal,
    CustomModal,
    ParameterCreate,
  },
  data() {
    return {
      filters: {
        key: [],
      },
      selectedFilters: {
        key: [],
      },
      noResultsFound: false,
      parametersMemory: [],
      parameters: {},
      pageSize: 10,
      showDeleteModal: false,
      selectedParameter: null,
      parameterModel: {
        key: '',
        value: '',
        description: '',
      },
      pageIndex: 1
    };
  },
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'key',
          label: this.$t('key'),
          component: 'FilterMultiSelect',
          options: this.filters.key,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'id',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm-12 col-md-6 col-lg-6',
          initialValue: this.selectedFilters.key,
          onChanged: this.onKeyChanged,
        },
      ];
    },
  },
  methods: {
    async loadInitialFilters() {
      await _getAllParameters()
        .then((response) => {
          this.parametersMemory = response.data;
          this.filters.key = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onKeyChanged(value) {
      if (value == null) {
        this.selectedFilters.key = [];
        return;
      }

      this.selectedFilters.key = [value];
    },
    async getParameters(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        key: this.selectedFilters.key.length
          ? this.selectedFilters.key[0].id
          : null,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getParameters(payload)
        .then(({ data }) => {
          this.parameters = data;
          this.filtered = true;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    goToCreateParameter() {
      this.$refs['create-modal-ref'].show();
    },
    onDeleteParameter(parameter) {
      this.showDeleteModal = true;
      this.selectedParameter = parameter;
    },
    onConfirmDelete() {
      _deleteParameter(this.selectedParameter.key)
        .then(async () => {
          this.showDeleteModal = false;
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          await this.getParameters();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    editParameter(parameter) {
      this.parameterModel = { ...parameter };
      this.$refs['create-modal-ref'].show();
    },
    async onSubmitCreate() {
      this.onCancelCreate();
      await this.getParameters();
      await this.loadInitialFilters();
    },
    onCancelCreate() {
      this.$refs['create-modal-ref'].hide();
      this.parameterModel = {
        key: '',
        value: '',
        description: '',
      };
    },
  },
  async mounted() {
    await this.loadInitialFilters();
  },
};
</script>
<style lang="scss" scoped>
::v-deep h6 {
  color: $color-primary;
}
::v-deep textarea {
  resize: none;
}
</style>

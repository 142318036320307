<template>
  <div class="col-sm-12 col-md-10 offset-md-1">
    <validation-observer ref="form-create-ref" novalidate>
      <div class="row">
        <div class="col d-flex justify-content-center my-4">
          <h6 class="text-uppercase">{{ $t('parametermaintenance') }}</h6>
        </div>
      </div>

      <div class="row">
        <sub-header :title="$t('completeParameterInformation')" />
      </div>

      <div class="row">
        <div class="col">
          <label>{{ $t('key') }}</label>
          <validation-provider v-slot="{ errors }" rules="required">
            <base-input
              v-model="parameterModel.key"
              :disabled="editMode"
              :error="errors[0]"
              :error-msg="$t('pleaseEnterParameterKey')"
            />
          </validation-provider>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <label>{{ $t('value') }}</label>
          <validation-provider v-slot="{ errors }" rules="required">
            <base-input
              v-model="parameterModel.value"
              :error="errors[0]"
              :error-msg="$t('pleaseEnterParameterValue')"
              :textarea="true"
            />
          </validation-provider>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <label>{{ $t('description') }}</label>
          <validation-provider v-slot="{ errors }" rules="required">
            <base-input
              v-model="parameterModel.description"
              :error="errors[0]"
              :error-msg="$t('pleaseEnterParameterDescription')"
              :textarea="true"
            />
          </validation-provider>
        </div>
      </div>

      <div class="row my-4">
        <div class="col col-cancel">
          <base-filled-button
            bg-color="#707070"
            class="btn-cancel my-1 mx-0"
            icon-class="far fa-times-circle"
            :on-click="
              () => {
                $emit('on-cancel');
              }
            "
            :text="$t('cancel')"
          />
        </div>
        <div class="col col-save">
          <base-filled-button
            class="button-right my-1 mx-0"
            icon-class="fad fa-save"
            :on-click="onCreateParameter"
            :text="$t('save')"
          />
        </div>
      </div>
    </validation-observer>

    <custom-modal id="go-create-modal" ref="go-create-modal-ref" size="md">
      <div class="back-container text-center">
        <i class="fal fa-exclamation-circle back-container__icon" />
        <div class="back-container__message text-center">
          {{ $t('proceedToSave') }}
        </div>
        <div class="d-flex flex-row mt-4">
          <button
            class="btn btn-secondary mx-4 flex-grow-1"
            type="button"
            @click="$refs['go-create-modal-ref'].hide()"
          >
            {{ $t('no') }}
          </button>
          <button
            class="btn btn-primary mx-4 flex-grow-1"
            type="button"
            @click="onConfirmCreate"
          >
            {{ $t('yes') }}
          </button>
        </div>
      </div>
    </custom-modal>
  </div>
</template>
<script>
import SubHeader from '@/components/SubHeader.vue';
import BaseInput from '@/components/BaseInput.vue';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import { submitSetupParameter as _submitSetupParameter } from '@/services/SetupParameterService';

export default {
  name: 'ParameterCreate',
  components: {
    SubHeader,
    BaseInput,
    CustomModal,
  },
  props: {
    parameterModel: {
      type: Object,
      default: () => {
        return {
          key: '',
          value: '',
          description: '',
        };
      },
    },
  },
  data() {
    return {
      editMode: false,
    };
  },
  methods: {
    async onCreateParameter() {
      if (!(await this.$refs['form-create-ref'].validate())) return;
      await this.$refs['form-create-ref'].reset();
      
      this.$refs['go-create-modal-ref'].show();
    },
    async onConfirmCreate() {
      const payload = { ...this.parameterModel };

      _submitSetupParameter(payload, this.editMode)
        .then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('on-submit');
        })
        .catch((error) => {
          this.ShowErrorToast(error.response.data.message);
          this.$refs['go-create-modal-ref'].hide();
        });
    },
  },
  async mounted() {
    if (this.parameterModel.key != '') {
      this.editMode = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.col-cancel {
  text-align: center;
}
.col-save {
  text-align: center;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .col-cancel {
    text-align: left;
  }
  .col-save {
    text-align: right;
  }
}

.custom-class {
  display: none;
}
</style>